const baseURL = 'https://admin.tomi.la/server/';

export async function getProfile (token) {
    const data = new FormData();
    data.append('token', token);
    let result = await loadJson (data, 'user_permission');
    return result[0];
}

export async function getPackages (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_packages');
}

export async function getPackageVersion (token, packageName) {
    const data = new FormData();
    data.append('token', token);
    data.append('packageName', packageName);
    return await loadJson (data, 'get_versions_by_package');
}


async function loadJson (data, url) {

  
    
    try {
        console.log(baseURL + url + ".php");
        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });
        const resp = await response.text();
        console.log(url + " plain answer:" + resp);
        if(resp !== "INVALID"){
            const jsonResult = JSON.parse(resp);
            return jsonResult;
        }else{
            return "INVALID";
        }
       
    } catch (error) {
        console.error('Error:' + error);
        return null;
    }
}


