import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './Login';
import App from "./App.js";
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';



const router = createBrowserRouter([{
  path: "/",
  element:<Login/>
},
{
  path: "/dashboard",
  element:<App/>
},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='1091848897000-pu33qq783tvs4vho7sncg7aj1udocd2o.apps.googleusercontent.com'>
    <RouterProvider router={router}/>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
