import { Navigate, redirect, useNavigate } from 'react-router-dom';
import logo from './assets/header.png';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

function Login() {
  

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

 

  
    function checkSession(credintial){
      const decoded= jwtDecode(credintial);
      console.log(decoded);
      navigate("Dashboard");
    }

  return (
    
    <div className="App">

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          TOMi Management System
        </p>
        <span>
          <GoogleLogin
          onSuccess={
            (credentialResponse) =>{
              console.log (credentialResponse?.credential);
      
              localStorage.setItem("token", credentialResponse?.credential);
              checkSession(credentialResponse?.credential);
              
            }}  
            onError={()=>{
              console.log("Login Failed");
            }}
          />
        </span>
        
      </header>
    </div>
  );
}


export default Login;
