import React from 'react';
import logo from '../assets/logo_solo.png';
import './Header.css';

function Header({usr_name}) {
  return (
    <header className="header">
      <div >
        <img src = {logo} alt='' width='110'/>
      </div>
      <p className="usr_name">{usr_name}</p>
      <button className="logout-button">Cerrar sesión</button>
    </header>
  );
}

export default Header;