import React, { useEffect, useState } from 'react';
import './TOMi8versions.css';
import { getPackages, getPackageVersion } from '../utils/services';
import {  useNavigate } from 'react-router-dom';

function TOMi8versions  () {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]); // Estado para las opciones del dropdown
  const [versions, setVersions] = useState([]);
  const [newVersion, setNewVersion] = useState({
    versionCode: '',
    versionName: '',
    fechaPublish: '',
    comments: '',
    current: '',
    destiny: ''
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVersion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected file:', file);
  };

  const addVersion = () => {
    
 
    setVersions((prevState) => [...prevState, {
      versionCode: '1',
      versionName: '13',
      fechaPublish: '41234',
      comments: 'zfasfsdfasd',
      current: '20',
      destiny: '120'
    }]);
  };

  useEffect(() => {

    async function fetchPackages() {
      const packageslist = await getPackages(token);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + packageslist[0].package_name);
        const packageNames = packageslist.map(element => element.package_name);
        setDropdownOptions(packageNames);
        
     }
    }

    fetchPackages();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

  const handleDropdownChange = (e) => {

    console.log("Dropdown changed to:", e.target.value);
    async function fetchVersions(packageName) {
      const packageVersionlist = await getPackageVersion(token, packageName);
      if (packageVersionlist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista Versions for : " + packageName + ": " +  packageVersionlist[0].description);

        const newVersions = packageVersionlist.map(item => ({
          versionCode: item.version,
          versionName: '',
          fechaPublish: item.release_date,
          comments: item.description,
          current: '',
          destiny: '',
        }));

        setVersions(newVersions);
        
     }
    }

    fetchVersions(e.target.value);
  };

  return (
    <div className="container">
      <div className="dropdown">
        <select className="dropdown-select" onChange={handleDropdownChange}>
           {dropdownOptions.map((option, index) => (
            <option key={index} value={option}  >
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="table-container">
        <table className="version-table">
          <thead>
            <tr>
              <th>Version Code</th>
              <th>Version Name</th>
              <th>Fecha Publish</th>
              <th>Comments</th>
              <th>Current</th>
              <th>Destiny</th>
            </tr>
          </thead>
          <tbody>
            {versions.map((version, index) => (
              <tr key={index}>
                <td>{version.versionCode}</td>
                <td>{version.versionName}</td>
                <td>{version.fechaPublish}</td>
                <td>{version.comments}</td>
                <td>{version.current}</td>
                <td>{version.destiny}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="new-version">
        <h3>New Version</h3>
        <div className="form-group">
          <label>Comments:</label>
          <textarea
            name="comments"
            value={newVersion.comments}
            onChange={handleInputChange}
            className="textarea"
          />
        </div>
        <div className="form-group">
          <label>Version Code:</label>
          <input
            type="text"
            name="versionCode"
            value={newVersion.versionCode}
            onChange={handleInputChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label>Version Name:</label>
          <input
            type="text"
            name="versionName"
            value={newVersion.versionName}
            onChange={handleInputChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label>Package:</label>
          <input type="file" onChange={handleFileChange} className="file-input" />
          <button onClick={addVersion} className="add-button">Agregar</button>
        </div>
      </div>
    </div>
  );
};

export default TOMi8versions;
