import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import TOMi8versions from './components/TOMi8versions';
import {getProfile} from "./utils/services";
import './App.css';
import {  useNavigate } from 'react-router-dom';

function App() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  let [devices_management, setDevice_management] = useState(undefined);
  let [logistics, setlogistics] = useState(undefined);
  let [project_management, setproject_management] = useState(undefined);
  let [talent_metrics, settalent_metrics] = useState(undefined);
  let [user_admin, setuser_admin] = useState(undefined);
  let [accountant, setaccountant] = useState(undefined);
  let [usr_name, setusrname] = useState("");

  useEffect(() => {
    async function fetchProfile() {
      const profile = await getProfile(token);
      if (profile==="INVALID"){
        navigate("/");
      }else{
        setusrname(profile.usr_name);
        setDevice_management(profile.devices_management);
        setlogistics(profile.logistics);
        setproject_management(profile.project_management);
        settalent_metrics(profile.talent_metrics);
        setuser_admin(profile.user_admin);
        setaccountant(profile.accountant);
     }
    }

    fetchProfile();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

  return (
    <div className="App">
      <Header usr_name={usr_name}/>
      <div className="main-content">
        <Sidebar
          devices_management={devices_management}
          logistics={logistics}
          project_management={project_management}
          talent_metrics={talent_metrics}
          user_admin={user_admin}
          accountant={accountant}
        />
        <TOMi8versions />
      </div>
    </div>
  );
}





export default App;
